import React, { Component } from "react";
import {
  BrowserRouter,
  HashRouter,
  Route,
  Router,
  Switch
} from "react-router-dom";
import { ThemeProvider } from "styled-components";
import datashopTheme from "@datashop/theme";
import history from "./history";

import { AppProvider } from "context/app.context";
import AppContainer from "./AppContainer";
import { ArticlePage } from "components/Articles";

/**
 * Define app routes
 * @param {Object} props App props with store data
 */
class Routes extends Component {
  state = {
    context: {
      isLoggedIn: window.localStorage.getItem("isLoggedIn") || false,
      providers: [],
      userDetails: null
    }
  };

  // Method to update context
  setContext = context => {
    this.setState(() => ({ ...this.state, context: { ...context } }));
  };

  render() {
    const { context } = this.state;
    const { setContext } = this;

    return (
      <AppProvider value={{ context, setContext }}>
        <ThemeProvider theme={datashopTheme}>
          <Router history={history}>
            <Route path="/" component={AppContainer} />
          </Router>
        </ThemeProvider>
      </AppProvider>
    );
  }
}

export default Routes;
