import React, { Component, Fragment } from "react";
import { createHashHistory } from "history";
import { Route, Switch, HashRouter as Router } from "react-router-dom";
import { ThemeProvider } from "styled-components";
import datashopTheme from "@datashop/theme";
import styled from "styled-components";
import App from "./App";
import Signup from "components/Auth/Signup";
import EvaluatePatient from "components/EvaluatePatient/EvaluatePatient";
import ProtectedRoute from "ProtectedRoute";
import VFDHome from "components/VFDHome/VFDHome";
import Messages from "components/Messages/Messages";
import NoPage from "components/NoPage";
import VirtualWalkInContainer from "components/VirtualWalkIn/VirtualWalkInContainer";
import VisitRequestContainer from "components/VisitRequest/VisitRequestContainer";
import PatientOnboarding from "components/PatientOnboarding";
import brandApi from "helpers/brandDetails";
import authApi from "helpers/authApi";
import { Loader } from "./packages/loader/";
import createToast from "helpers/toastHelper";
import ResizeHOC from "helpers/ResizeHOC";
import { DesktopView, MobileView } from "components/Views";
import mobileCheck from "helpers/mobileCheck";
import { LogoutComponent } from "./components/Auth/Logout";
import TeleVisit from "components/TeleVisit/TeleVisit";
import PatientInsuranceContainer from "components/PatientInsurance/PatientInsuranceContainer";
import { TermsAndCondition } from "components/Auth/TermsAndCondition";
import { ArticlePage } from "components/Articles";

// Import Context
import AppContext from "./context/app.context";

/**
 * Define app routes
 * @param {Object} props App props with store data
 */

const LoaderWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  justify-content: center;
`;

const openRoutes = ["room", "article", "document", "patient-onboarding"];

const getPracticeName = () => {
  let url = window.location.href;
  const subrouteStartIndex = url.indexOf("/practice");
  if (subrouteStartIndex > -1) {
    const subRouteParts = url.slice(subrouteStartIndex).split("/");
    return subRouteParts[2] ? subRouteParts[2].replace("#", "") : "";
  }
  return "";
};

class AppContainer extends Component {
  static contextType = AppContext;

  baseUrl;
  state = {
    practiceDetails: null,
    loading: true,
    gettingBot: true,
    detailsError: null,
    organization_id: null,
    baseUrl: getPracticeName() ? `/practice/${getPracticeName()}/` : "/"
  };

  getPracticeDetailsValue = (practiceData, practiceName) => {
    const currentPractice = practiceData.filter(
      practice => practice.practiceKey === practiceName
    );

    return currentPractice[0] && !currentPractice[0].overrideNetworkPresence
      ? currentPractice[0]
      : null;
  };
  getPracticeDetails = () => {
    const practiceName = getPracticeName();
    const subdomain = window.location.hostname.split(".")[0];

    brandApi
      .get(`/organizations/branding-config/${subdomain}`)
      .then((data = {}) => {
        const details = data.data;
        const { organization_id } = details;
        const practiceDetails =
          this.getPracticeDetailsValue(details.practiceData, practiceName) ||
          details;

        if (!practiceDetails && this.state.baseUrl !== "/") {
          this.props.history.push(`noPage`);
        } else {
          this.setState({
            practiceDetails,
            organization_id,
            loading: false,
            details
          });
        }
      })
      .catch(error => {
        this.setState(
          {
            loading: false,
            configNotAvailable: true
          },
          () => createToast({ title: "Unable to Fetch Branding Details" })
        );
      });
  };

  getCurrentUser = () => {
    const { context, setContext } = this.context;
    if (!context.userDetails) {
      authApi.defaults.headers.Authorization = `Bearer ${localStorage.getItem(
        "token"
      )}`;
      authApi
        .get(`/auth/me`)
        .then((response = {}) => {
          setContext({
            ...context,
            userDetails: response.data
          });
        })
        .catch(error => {
          createToast({ title: "Unable to Fetch User Details" });
        });
    }
  };

  componentDidMount() {
    if (openRoutes.some(path => this.props.location.pathname.includes(path))) {
      this.setState(prevState => ({ ...prevState, loading: false }));
    } else {
      this.getPracticeDetails();
      const isLoggedIn = localStorage.getItem("isLoggedIn");

      if (isLoggedIn === "true") {
        this.getCurrentUser();
      }
    }
  }

  getRoutes = () => {
    const { baseUrl } = this.state;

    return (
      <Router history={createHashHistory({ basename: baseUrl })}>
        <ThemeProvider theme={datashopTheme}>
          <App>
            <ResizeHOC>
              {!openRoutes.some(path =>
                this.props.location.pathname.includes(path)
              ) && <LogoutComponent {...this.props} />}
              <Switch>
                <ProtectedRoute
                  exact
                  path="/"
                  component={VFDHome}
                  {...this.state}
                />
                <ProtectedRoute
                  exact
                  path={`/virtual-walkin`}
                  component={VirtualWalkInContainer}
                  {...this.state}
                />
                <ProtectedRoute
                  exact
                  path={`/visit-request`}
                  component={VisitRequestContainer}
                  {...this.state}
                />
                <ProtectedRoute
                  exact
                  path={"/messages"}
                  component={Messages}
                  {...this.state}
                />
                <Route
                  exact
                  render={props => <Signup {...props} {...this.state} />}
                  path={"/signup"}
                />
                <Route
                  exact
                  path={"/accept-consent"}
                  component={TermsAndCondition}
                />
                <Route exact path={"/televisit"} component={TeleVisit} />
                <Route
                  exact
                  path={"/room"}
                  render={props => (
                    <PatientInsuranceContainer {...props} {...this.state} />
                  )}
                />
                <Route
                  exact
                  path={
                    "/evaluate/assessment/:assessmentId/response/:responseId/patient/:patientId"
                  }
                  render={props => (
                    <EvaluatePatient {...props} {...this.state} />
                  )}
                />
                <Route
                  exact
                  path={"/patient-onboarding"}
                  component={PatientOnboarding}
                />
                <Route
                  exact
                  path={"/article/:documentId/*"}
                  component={ArticlePage}
                />
                <Route
                  exact
                  path="/document/:documentId"
                  component={ArticlePage}
                />
                <Route
                  exact
                  path="/document/:documentId/:sectionId"
                  component={ArticlePage}
                />
                <Route path={"/noPage"} component={NoPage} />
                <Route path="*" component={NoPage} />
              </Switch>
            </ResizeHOC>
          </App>
        </ThemeProvider>
      </Router>
    );
  };

  render() {
    const { loading, practiceDetails } = this.state;
    let logoUrl = "";
    let address = "";
    let phone = "";

    if (practiceDetails) {
      logoUrl = practiceDetails.logoUrl;
      address = practiceDetails.address;
      phone = practiceDetails.phoneNo;
    }

    if (this.state.loading) {
      return (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      );
    }

    if (openRoutes.some(path => this.props.location.pathname.includes(path))) {
      return <Fragment>{this.getRoutes()}</Fragment>;
    }

    if (mobileCheck()) {
      return (
        <MobileView {...this.props}>
          <Fragment>{this.getRoutes()}</Fragment>
        </MobileView>
      );
    } else {
      return (
        <DesktopView
          {...this.props}
          logoUrl={logoUrl}
          address={address}
          phone={phone}
          loading={loading}
        >
          <Fragment>{this.getRoutes()}</Fragment>
        </DesktopView>
      );
    }
  }
}

export default AppContainer;
