import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Articles } from "innovaccer";
import "innovaccer/dist/index.css";

export const ArticlePage = () => {
  const params = useParams();
  const { documentId, sectionId } = params;
  const authToken = params[0];

  if (!!authToken) {
    localStorage.setItem("authToken", authToken);
  }

  return (
    <Articles
      documentId={documentId}
      sectionId={sectionId}
      authToken={authToken || localStorage.getItem("authToken")}
    />
  );
};
