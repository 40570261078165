import mixpanel from "mixpanel-browser";

import {
  mixpanelConfigPreProd,
  mixpanelConfigStaging,
  mixpanelConfigProd
} from "../services/mixpanelService";

const envType = process.env.REACT_APP_BUILD;

const mixpanelConfiguration =
  envType === "PROD"
    ? mixpanelConfigProd
    : envType === "PREPROD"
    ? mixpanelConfigPreProd
    : mixpanelConfigStaging;

const { NODE_ENV } = process.env;

let prefix =
  localStorage.getItem("access") === "PROVIDER" ? "Provider" : "Staff";

const actions = {
  mixpanelInitiated: false,
  track: (name, props, appendPrefix = false) => {
    if (NODE_ENV && mixpanelConfiguration.isActive)
      mixpanel.track(appendPrefix ? `${prefix} - ${name}` : name, props);
  },
  register: defaultProps => {
    if (NODE_ENV && mixpanelConfiguration.isActive)
      mixpanel.register(defaultProps);
  },
  unregister: defaultProps => {
    if (NODE_ENV && mixpanelConfiguration.isActive)
      mixpanel.unregister(defaultProps);
  },
  identify: id => {
    if (NODE_ENV && mixpanelConfiguration.isActive) {
      mixpanel.identify(id);
      prefix =
        localStorage.getItem("access") === "PROVIDER" ? "Provider" : "Staff";
    }
  },
  alias: id => {
    if (NODE_ENV && mixpanelConfiguration.isActive) mixpanel.alias(id);
  },
  people: {
    set: props => {
      if (NODE_ENV && mixpanelConfiguration.isActive)
        mixpanel.people.set(props);
    }
  },
  init: () => {
    return new Promise(resolve => {
      const init = mixpanel.init(mixpanelConfiguration.token);
      resolve(init);
    });
  }
};

export { actions as Mixpanel };
