import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { orientationHandler, isElectron } from "utils/checkMobile";
import { isSupported } from "twilio-video";
import ViewMode from "components/ViewMode";
import NotSupported from "components/NotSupported";
import { setItem, getItem } from "helpers/localStore";
import { Mixpanel } from "helpers/mixpanelHelper";

const ErrorPage = styled.div`
  margin: auto;
  font-size: 18px;
  font-weight: bold;
`;

const ResizeHOC = ({ children }) => {
  const [view, setView] = useState(orientationHandler());
  const [isAllFeatureAvailable, setIsAllFeatureAvailable] = useState("true");

  useEffect(() => {
    window.addEventListener("orientationchange", orientationHandlerL);
    if (getItem("isAllFeatureAvailable") === null) {
      setItem("isAllFeatureAvailable", "true");
    } else {
      setIsAllFeatureAvailable(getItem("isAllFeatureAvailable"));
    }
    return () =>
      window.removeEventListener("orientationchange", orientationHandlerL);
  }, []);

  const orientationHandlerL = () => {
    setView(orientationHandler());
  };

  const clickHandler = () => {
    Mixpanel.track("Continued on unsupported browser", {
      Category: "InNote Provider - Browser"
    });
    setItem("isAllFeatureAvailable", "false");
    setIsAllFeatureAvailable("false");
  };

  if (view === "landscape") {
    return <ViewMode />;
  }

  if (!isElectron() && !isSupported && isAllFeatureAvailable === "true") {
    return <NotSupported onClick={clickHandler} />;
  }

  return <>{children}</>;
};

export default ResizeHOC;
